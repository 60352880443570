<template>
  <div>
    <v-container class="max-w-full">
      <v-row class="">
        <v-col class="pt-0" cols="10">
          <v-select
            :items="items"
            v-model="customerType"
            label="Tip client"
            @change="onCustomerChanged"
          ></v-select>
          <div>Datorită unor limitări tehnice, discount-ul la înregistrare și valabilitatea discount-ului sunt comune pentru client tip Companie și Persoană fizică</div>
          <v-container v-if="customerType">
            <v-row class="items-center">
              <v-col class="pt-0" cols="6">
                <v-text-field
                  v-model="registrationDiscount"
                  label="Discount la inregistrare"
                ></v-text-field>
                <error-messages :errors="errors.registrationDiscount" />
              </v-col>
            </v-row>
            <v-row class="items-center">
              <v-col class="pt-0" cols="6">
                <v-text-field
                  v-model="registrationDiscountPeriod"
                  label="Valabilitate discount inregistrare (in luni)"
                ></v-text-field>
                <error-messages :errors="errors.registrationDiscountPeriod" />
              </v-col>
            </v-row>
            <v-row class="items-center">
              <v-col class="pt-0" cols="6">
                <v-text-field
                  v-model="stepInterval"
                  label="Interval praguri (in luni)"
                ></v-text-field>
                <error-messages :errors="errors.stepInterval" />
              </v-col>
            </v-row>
            <v-row v-for="(item, index) in steps" :key="index">
              <v-col>
                <v-text-field v-model="item.min" label="Min"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  v-model="item.discount"
                  label="Discount"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-btn @click="removeCurrentStep(index)" icon>
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <error-messages :errors="errors.steps" />
            <v-row>
              <v-col cols="12" class="text-right">
                <v-btn @click="addNewStep">Adauga prag nou</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn primary color="primary" class="primary-button" @click="save">Salveaza</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ErrorMessages from "@/components/general-form/ErrorMessages";
export default {
  name: "Index",
  components: {
    ErrorMessages,
  },
  data() {
    return {
      customerType: "",
      items: [],
      steps: [],
      stepInterval: 1,
      registrationDiscount: 1,
      registrationDiscountPeriod: 0,
      errors: {}
    };
  },
  mounted() {
    this.$http.get("helpers/customer-types").then(({ data }) => {
      console.log("customer types", data);
      this.items = data.map((customer) => {
        return {
          text: customer.name,
          value: customer.machine_name,
        };
      });
    });
  },
  methods: {
    onCustomerChanged() {
      console.log("selected customer type", this.customerType);
      this.$http
        .get(`discount-steps/${this.customerType}`)
        .then(({ data }) => {
          this.steps = data.steps;
          this.stepInterval = data.stepInterval;
          this.registrationDiscount = data.registrationDiscount;
          this.registrationDiscountPeriod = data.registrationDiscountPeriod;
        });
    },

    addNewStep() {
      console.log("adding new step");
      this.steps.push({
        min: 0,
        discount: 0,
      });
    },

    removeCurrentStep(index) {
      this.steps.splice(index, 1);
    },

    save() {
      this.errors = {};
      let payload = {
        stepInterval: this.stepInterval,
        steps: this.steps,
        registrationDiscount: this.registrationDiscount,
        registrationDiscountPeriod: this.registrationDiscountPeriod
      };

      this.$http
        .put(`discount-steps/${this.customerType}`, payload)
        .then(() => {
          this.$vs.notify({
            title: "Succes!",
            text: `Pragurile pentru tip client ${this.customerType} au fost salvate.`,
            color: "success",
          });
        })
        .catch((error) => {
          if ((error.response.status = 422)) {
            this.errors = error.response.data.errors;
          }
          this.$vs.notify({
            title: "Eroare",
            text: error.response.data.message,
            color: "danger",
          });
        });
    },
  },
};
</script>
